import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { useEffect } from 'react';
import styled from 'styled-components';

import Icon from '../icon';

export type Props = {
  onClose: () => void;
  children: React.ReactElement;
};

const Modal: React.FC<Props> = ({ onClose, children }) => {
  useEffect(() => {
    const closeOnEsc = (ev: KeyboardEvent) => {
      if (ev.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', closeOnEsc);
    return () => {
      document.removeEventListener('keydown', closeOnEsc);
    };
  }, [onClose]);

  return (
    <CoreModal>
      <Close>
        <Icon icon={faTimes} onClick={onClose} size="2x" style={{ cursor: 'pointer' }} />
      </Close>
      {children}
      <BackDrop />
    </CoreModal>
  );
};

export default Modal;

const BackDrop = styled.div``;

const CoreModal = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 500;
  transform: translate(-50%, -50%);
  border-radius: 1.6rem;
  border: 1px solid #afb7ca;
  padding: 1rem;
  background-color: #ffffff;
  box-shadow: 0 0 3px 0 rgba(10, 35, 92, 0.25), 0 16px 48px -16px rgba(10, 35, 92, 0.5);
  @media (max-width: 960px) {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transform: unset;
  }
`;

const Close = styled.div`
  display: flex;
  justify-content: flex-end;
`;
