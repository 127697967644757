import { inspect } from '@xstate/inspect';
import ReactDOM from 'react-dom';

import App from './app';
import Providers from './providers';
import { startDocumentService } from './state/document.machine';
import { startPreviewService } from './state/preview.machine';

process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_XSTATE &&
  inspect({ iframe: false });
startDocumentService();
startPreviewService();
// function render() {
//   // <React.StrictMode> has been removed, as it caused functional setState to be invoked twice, causing duplicate state changes in the editor undo stack.
//   // https://github.com/facebook/react/issues/12856#issuecomment-390206425
//   ReactDOM.render(
//     <Providers>
//       <App />
//     </Providers>,
//     document.querySelector('#root'),
//   );
// }

// render();

ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.querySelector('#root'),
);

// do we need this? ./routes/routes no longer exist
// if (process.env.NODE_ENV === 'development' && module.hot) {
//   module.hot.accept('./routes/routes', render);
// }
