import axios from 'axios';
import { useMutation } from 'react-query';

import { apiClient } from '../api-client';

/**
 * @deprecated use `useFetchSession` instead
 */
export function useGetSessionId() {
  const url = `/api/v1/live-session/`;

  return useMutation<GetSessionIdResponse>(() => axios.post(url).then((res) => res.data));
}

export async function fetchSession() {
  const url = `/api/v1/live-session/`;
  const res = await apiClient.post<GetSessionIdResponse>(url, {});
  return res.data;
}
interface GetSessionIdResponse {
  data: {
    liveSessionId: string;
  };
}
