import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { useHistory } from 'react-router';

import { useDocument } from '../context/document-context';
import Button from './button';
import Icon from './icon';

const ClearButton: React.FC = () => {
  const { push } = useHistory();
  const { state, send } = useDocument();
  const shouldShow =
    ['uploaded', 'processing', 'documentProcessed'].includes(state.value as string) || null;

  const onClick = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want clear? This action can't be undone",
    );
    if (userConfirmed) {
      send({ type: 'UPLOAD_CLEAR' });
      push('/');
    }
  };

  return (
    shouldShow && (
      <Button onClick={onClick} startAdornment={<Icon icon={faTimes} spacer={32} />}>
        Clear
      </Button>
    )
  );
};

export default ClearButton;
