import { Link, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import DevelopmentPanel from './components/_development-panel.tsx';
import ClearButton from './components/clear-button';
import { Environment } from './components/environment';
import { Logo } from './components/logo';
import UploadButton from './components/upload-button';
import { Footer } from './layout/footer';
import Layout from './layout/layout';
import MainPage from './routes/main-page';
import { SegmenterTestPage } from './routes/segmenter-test-page/segmenter-test-page';

const App: React.FC = () => {
  return (
    <Layout
      header={
        <>
          <Link to="/">
            <Logo />
          </Link>
          <Actions>
            <ClearButton />
            <UploadButton />
          </Actions>
        </>
      }
      footer={<Footer />}
    >
      <Switch>
        <Route path="/segmenter" component={SegmenterTestPage} />
        <Route
          path="/:sessionId?/:selectedSuggestion?/:documentId?"
          render={(props) => <MainPage {...props} />}
        />
      </Switch>
      <Environment />
      <DevelopmentPanel />
    </Layout>
  );
};

export default App;

const Actions = styled.div`
  display: flex;
`;
