import * as s from './footer.styles';

const SEP = '\u2002\u2022\u2002';

export const Footer: React.FC = () => (
  <s.pageFooter>
    Paperpal is a CACTUS Solution
    {SEP}
    Visit{' '}
    <s.footerLink
      href="https://paperpal.com?utm_medium=app_footer&utm_source=paperpal_live_app&utm_campaign=internal"
      target="_blank"
    >
      paperpal.com
    </s.footerLink>
    {SEP}
    Please contact{' '}
    <s.footerLink href="mailto:support@paperpal.com">support@paperpal.com</s.footerLink> if you need
    help
    {SEP}
    <s.footerLink href="#">Terms and Policies</s.footerLink>
    {SEP}
    {process.env.REACT_APP_VERSION}
  </s.pageFooter>
);
