import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { fetchSnapshot } from '../../api/snapshot/fetch-snapshot.api';
import { Actions } from '../../state/redux-store';
import { getFlattenedSuggestions } from '../../state/sentences.model';
import { SentencesSelector } from '../../state/sentences/sentences.slice';
import EditorWorkspace from './editor-workspace';

type Parameters = {
  sessionId: string;
  selectedSuggestion: string;
};

const EditorWorkspaceHandler: React.FC = () => {
  useSelectedSuggestionOutOfBoundsHandler();
  useStateChangedDispatcher();
  useUrlChangedHandler();
  useFetchSnapshot();

  return <EditorWorkspace />;
};

export default EditorWorkspaceHandler;

function useSelectedSuggestionOutOfBoundsHandler() {
  const { sessionId, selectedSuggestion } = useParams<Parameters>();
  const dispatch = useDispatch();
  const sentencesState = useSelector(SentencesSelector);
  const history = useHistory();

  const suggestions = getFlattenedSuggestions(sentencesState);

  const suggestionsCount = suggestions.length;
  useEffect(() => {
    const noSuggestionsAndOutOfBounds = selectedSuggestion && suggestionsCount === 0;
    const unset = !selectedSuggestion && suggestionsCount > 0;
    const outOfBounds =
      selectedSuggestion &&
      suggestionsCount > 0 &&
      Number.parseInt(selectedSuggestion) > suggestionsCount - 1;

    if (noSuggestionsAndOutOfBounds) {
      history.push(`/${sessionId}`);
    } else if (unset || outOfBounds) {
      history.push(`/${sessionId}/0`);
    }
  }, [sessionId, selectedSuggestion, suggestionsCount, history, dispatch]);
}

function useStateChangedDispatcher() {
  const dispatch = useDispatch();
  const sentencesState = useSelector(SentencesSelector);

  useEffect(() => {
    if (sentencesState) {
      dispatch(Actions.stateChanged(sentencesState));
    }
  }, [sentencesState, dispatch]);
}

function useUrlChangedHandler() {
  const { sessionId, selectedSuggestion } = useParams<Parameters>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (sessionId) {
      dispatch(Actions.setSessionIdAndSuggestion({ sessionId, selectedSuggestion }));
    }
  }, [sessionId, selectedSuggestion, dispatch]);
}

function useFetchSnapshot() {
  const { sessionId } = useParams<Parameters>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (sessionId) {
      dispatch(fetchSnapshot(sessionId));
    }
  }, [sessionId, dispatch]);
}
