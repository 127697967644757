import React from 'react';
import styled from 'styled-components';

type BaseButtonStyleProps = {
  fullWidth?: boolean;
  variant?: 'solid';
  color?: 'primary';
  startAdornment?: React.ReactElement;
  endAdornment?: React.ReactElement;
  size?: 'small' | 'regular' | 'large';
};

type Props = BaseButtonStyleProps & {
  onClick?: (event: React.SyntheticEvent) => void;
  type?: 'reset' | 'submit' | 'button';
};

const Button: React.FC<Props> = ({ children, startAdornment, endAdornment, ...props }) => {
  return (
    <StyledButton {...props}>
      {startAdornment}
      {children}
      {endAdornment}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled.button<BaseButtonStyleProps>`
  background: ${({ theme, color, variant }) =>
    color && variant === 'solid' ? theme.palette[color].main : 'transparent'};
  color: ${({ theme, color, variant }) =>
    color && variant === 'solid' ? theme.palette[color].textContrast : theme.palette.text.main};
  padding: 8px 24px;
  height: 48px;
  box-shadow: ${({ variant }) =>
    variant === 'solid' ? '0px 2px 8px -4px rgba(10, 35, 92, 0.25)' : 'none'};
  border-radius: 8px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ variant }) => (variant === 'solid' ? 14 : 16)}px;
  font-weight: ${({ variant }) => (variant === 'solid' ? 600 : 400)};
  width: ${({ fullWidth }) => fullWidth && '100%'};
  transition: transform 0.2s;
  cursor: pointer;
  &:hover {
    transform: translateY(-2px);
  }
  &:focus {
    outline: none;
  }
  &:active {
    transform: translateY(2px);
  }
`;
