import { forwardRef } from 'react';
import styled from 'styled-components';

import CoreHelperText from './core-helper-text';
import CoreLabel from './core-label';
import type { FieldBase } from './types';

type Props = FieldBase & {
  options: Array<{ value: string | number; label: string }>;
  label: string;
};
const Select = forwardRef<any, Props>(
  ({ options, label, id, errors, className, ...props }, reference) => {
    const error = errors[props.name];
    return (
      <Label className={className}>
        <span>{label}</span>
        <StyledSelect id={id || props.name} {...props} ref={reference}>
          <option hidden disabled selected />
          {options.map((item) => {
            return (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </StyledSelect>
        <HelperText>{error?.message}</HelperText>
      </Label>
    );
  },
);

export default Select;

const Label = styled(CoreLabel)`
  color: #0a235c;
  > span {
    display: block;
    margin-left: 0.75rem;
    margin-bottom: 0.75rem;
  }
`;

const HelperText = styled(CoreHelperText)`
  color: ${({ theme }) => theme.palette.error.main};
`;

const StyledSelect = styled.select<{ fullWidth?: boolean; error?: boolean }>`
  font-size: 1rem;
  border-radius: 0.5rem;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(10, 35, 92, 0.5), 0 4px 16px -8px rgba(10, 35, 92, 0.25);
  width: 100%;
  line-height: 1.5rem;
  outline: none;
  appearance: none;
  cursor: pointer;
  border: 1px solid ${({ error, theme }) => (error ? theme.palette.error.main : 'transparent')};
  padding: 0.75rem;
  display: grid;
  &:focus {
    border-color: #0060ff;
  }
`;
