import { apiClient } from '../api-client';

export type IntentPaymentResult = {
  intent: {
    paymentIntentSecret: string;
    stripePublishableKey: string;
  };
  apiResponseStatus: string;
  status: number;
};
type IntentPaymentArgs = {
  sessionId: string;
  documentId: string;
};

export type CheckSubmissionResult = {
  status: 'EMAIL_SENT' | 'PAYMENT_REFUNDED' | 'PAYMENT_COMPLETED' | 'PAYMENT_INITIATED';
  intent: {
    paymentIntentSecret: string;
    stripePublishableKey: string;
  };
  downloadAvailableDays: number;
  downloadExpired: boolean;
};

export async function intentPayment({ sessionId, documentId }: IntentPaymentArgs) {
  return await apiClient.post<IntentPaymentResult>(
    `/api/${sessionId}/payment/${documentId}/prepare`,
  );
}

export async function checkSubmission(documentId: string) {
  try {
    return await apiClient.get<CheckSubmissionResult>(`/api/submission/${documentId}/info `);
  } catch {
    return null;
  }
}
