import styled from 'styled-components';

const EnvironmentLabel = styled.div`
  display: block;
  border-radius: 0.3rem;
  color: #fff;
  font-size: ${({ theme }) => theme.fontSize.regular};
  font-weight: 500;
  padding: 0.3rem 1rem;
  background: rgb(51, 156, 143) none repeat scroll 0% 0%;
  width: 220px;
  text-align: center;
  position: absolute;
  transform: rotate(-45deg);
  left: -70px;
  top: 27px;
`;

export const Environment: React.FC = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  if (apiBaseUrl?.includes('localhost')) {
    return <EnvironmentLabel>local</EnvironmentLabel>;
  } else if (apiBaseUrl?.includes('dev')) {
    return <EnvironmentLabel>dev</EnvironmentLabel>;
  } else if (apiBaseUrl?.includes('stage')) {
    return <EnvironmentLabel>stage</EnvironmentLabel>;
  } else {
    return null;
  }
};
