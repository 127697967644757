import styled from 'styled-components';

import ModalTitle from './modal-title';

const Wrapper = styled.div``;

const TocModal: React.FC = () => {
  return (
    <Wrapper>
      <ModalTitle>Paperpal Terms and Policies</ModalTitle>
      <p>TERMS OF USE</p>
    </Wrapper>
  );
};

export default TocModal;
