import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import { faAngleUp } from '@fortawesome/pro-light-svg-icons/faAngleUp';

import Icon from '../../../components/icon';
import { Spinner } from '../../../components/spinner';
import { usePreviewMachine } from '../../../state/preview.machine';
import { getTokens } from '../../../state/sentences/position-markers/token-mapper';
import * as s from './suggestions-browser.styles';

const SuggestionsBrowser: React.FC = () => {
  const [state, send] = usePreviewMachine();

  const allSuggestionSentencePairs = state.context.sentences
    .map((sentence) =>
      sentence.suggestions.map((suggestion) => ({
        sentence,
        suggestion,
        tokens: getTokens(sentence.text, suggestion.start, suggestion.end, suggestion.replacement),
      })),
    )
    .flat();

  const hasNoSuggestions = allSuggestionSentencePairs.length === 0;
  const fetchingSuggestions = state.context.sentences.some((sentence) => !sentence.done);

  if (fetchingSuggestions && hasNoSuggestions) {
    return (
      <s.spinnerContainer>
        <Spinner size={64} />
      </s.spinnerContainer>
    );
  }

  if (hasNoSuggestions) {
    // it's returning a div instead of null, because the flex styling needs and html element
    return <div />;
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const selectedSuggestionPair =
    allSuggestionSentencePairs.find(
      ({ suggestion }) => suggestion.id === state.context.selectedSuggestionId,
    ) || allSuggestionSentencePairs[0];

  const { suggestion, tokens } = selectedSuggestionPair;

  const color = suggestion.suppressionCategory === 'other' ? 'grey' : 'default';
  return (
    <div>
      <s.header>
        <s.counter>
          {allSuggestionSentencePairs.indexOf(selectedSuggestionPair) + 1} of{' '}
          {allSuggestionSentencePairs.length}
        </s.counter>
        <s.spinner>{fetchingSuggestions && <Spinner />}</s.spinner>
        <s.arrow
          onClick={() => {
            send('SELECT_PREVIOUS_SUGGESTION');
          }}
        >
          <Icon icon={faAngleUp} size="lg" />
        </s.arrow>
        <s.arrow
          onClick={() => {
            send('SELECT_NEXT_SUGGESTION');
          }}
        >
          <Icon icon={faAngleDown} size="lg" />
        </s.arrow>
      </s.header>
      <s.suggestion color={color}>
        <s.category>{suggestion?.category}</s.category>
        <s.explanation>{suggestion?.explanation}</s.explanation>
        <s.text>
          ... {tokens[0]}
          <s.replacement>{tokens[1]}</s.replacement>
          {tokens[2]} ...
        </s.text>
      </s.suggestion>
    </div>
  );
};

export default SuggestionsBrowser;
