import styled from 'styled-components';

export const pageFooter = styled.footer`
  color: rgb(163, 172, 194);
  font-size: 12px;
  height: 120px;
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  text-align: center;
  flex-shrink: 0;
`;

export const footerLink = styled.a`
  color: inherit;
  text-decoration: underline;
  display: contents;
`;
